@if (isMobile)
{
  <ion-modal id="modal" #modal [initialBreakpoint]="modalBreakpoints[2]" [breakpoints]="modalBreakpoints">
    <ng-template>
      <div id="layout">
        <h1>My Communities</h1>
        <app-community-list></app-community-list>
      </div>
    </ng-template>
  </ion-modal>
}
@else
{
  <div id="main-container" class="ion-padding">
    <h1>My Communities</h1>
    <app-community-list></app-community-list>
  </div>
}
