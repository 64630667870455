<ion-item (click)="seeCommunity()">
  <div id="container">
    <app-avatar id="avatar" type="community" [image]="communityImage"></app-avatar>
    <div class="flex">
      <p id="community-name">{{ communityName }}</p>
      <div id="actions">
        <app-simple-button id="see-content" class="action" text="See content" (click)="seeContent($event)"></app-simple-button>
        <app-simple-button id="leave" class="action" [text]="isBookmark ? 'Delete' : 'Leave'" type="warning" (click)="leave($event)"></app-simple-button>
      </div>
    </div>
  </div>
</ion-item>
