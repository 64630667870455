import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Step, StepState } from '../../step/step.component';
import { StepData } from '../../services/onboarding.service';

@Component({
  selector: 'app-welcome-step',
  templateUrl: './welcome-step.component.html',
  styleUrls: ['./welcome-step.component.scss'],
})
export class WelcomeStepComponent implements Step, OnInit, OnDestroy {

  constructor() { }

  confirm: Subject<void> = new Subject<void>();
  data?: StepData;
  processed = new EventEmitter<StepState>();
  
  confirmSubscription?: Subscription;

  ngOnInit() {
    this.confirmSubscription = this.confirm.subscribe(() => {
      this.processed.emit({success: true});
    });
  }

  ngOnDestroy() {
    this.confirmSubscription?.unsubscribe();
  }
}
