import { Injectable } from '@angular/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

/**
 * Service to interact with device local storage.
 * On native platforms, this service uses secure local storage. On web, it falls back to regular local storage.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  
  constructor() {}

  /**
   * Set a value in a secure local storage (will fallback to regular local storage on web)
   * @param key Key of the item to store
   * @param value Value of the item to store
   */
  async setItem(key: string, value: string): Promise<void> {
    try {
      await SecureStoragePlugin.set({
        key,
        value
      });
    } catch (_) {
    }
  }

  /**
   * Get a value from a secure local storage (**native only** - will fallback to regular local storage on web)
   * @param key Key of the item to retrieve
   * @returns The value of the item or null if the item does not exist
   */
  async getItem(key: string): Promise<string | null> {
    try {
      const { value } = await SecureStoragePlugin.get({ key });
      return value;
    } catch (_) {
      return null;
    }
  }

  /**
   * Remove an item from secure local storage (**native only** - will fallback to regular local storage on web)
   * @param key Key of the item to remove
   * @returns True if the item was removed, false if the item did not exist
   */
  async removeItem(key: string): Promise<boolean> {
    try {
      const { value } = await SecureStoragePlugin.remove({ key });
      return value;
    } catch (_) {
      return false;
    }
  }

  /**
   * Clear all items from secure local storage (**native only** - will fallback to regular local storage on web)
   */
  async clear(): Promise<void> {
    try {
      await SecureStoragePlugin.clear();
    } catch (_) {
    }
  }
}
