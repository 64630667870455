import { Component, ComponentRef, Injectable, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { StepComponent, StepState } from '../step/step.component';
import { UserService } from 'app/src/app/data/user.service';
import { catchError, first, firstValueFrom, lastValueFrom, Observable, of, Subject } from 'rxjs';
import { AlertService } from 'app/src/app/utils/alert.service';
import { WelcomeStepComponent } from '../steps/welcome-step/welcome-step.component';
import { UsernameStepComponent } from '../steps/username-step/username-step.component';
import { TutorialStepComponent } from '../steps/tutorial-step/tutorial-step.component';
import { FinalStepComponent } from '../steps/final-step/final-step.component';

export type StepData = {
  step: number,
  skipable: boolean,
  component: Type<any>
  additionalData?: any,
  dependsOn?: number,
  state?: StepState
}

type Step = {
  path: string,
  name: string,
  data: StepData,
  component: typeof StepComponent
}

export const steps: Step[] = [
  {
    path: '0',
    name: 'Welcome',
    data: {
      step: 0,
      skipable: false,
      component: WelcomeStepComponent
    },
    component: StepComponent
  },
  {
    path: '1',
    name: 'Username',
    data: {
      step: 1,
      skipable: false,
      component: UsernameStepComponent
    },
    component: StepComponent
  },
  {
    path: '2',
    name: 'Tutorial 1',
    data: {
      step: 2,
      skipable: true,
      additionalData: { tutorial: 1 },
      component: TutorialStepComponent
    },
    component: StepComponent
  },
  {
    path: '3',
    name: 'Tutorial 2',
    data: {
      step: 3,
      skipable: true,
      dependsOn: 2,
      additionalData: { tutorial: 2 },
      component: TutorialStepComponent
    },
    component: StepComponent
  },
  {
    path: '4',
    name: 'Tutorial 3',
    data: {
      step: 4,
      skipable: true,
      dependsOn: 3,
      additionalData: { tutorial: 3 },
      component: TutorialStepComponent
    },
    component: StepComponent
  },
  {
    path: '5',
    name: 'Tutorial 4',
    data: {
      step: 5,
      skipable: true,
      dependsOn: 4,
      additionalData: { tutorial: 4 },
      component: TutorialStepComponent
    },
    component: StepComponent
  },
  {
    path: '6',
    name: 'Final',
    data: {
      step: 6,
      skipable: false,
      component: FinalStepComponent
    },
    component: StepComponent
  },
]

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private router: Router, private userService: UserService, private location: Location, private alert: AlertService) { }

  currentStep = 0;

  private onboardingCompleted: Subject<void> = new Subject();
  onboardingCompleted$: Observable<void> = this.onboardingCompleted;

  public goToStep(step: number) {
    this.currentStep = step;
    this.router.navigate([`/onboarding/${steps[step].path}`]);
  }

  public skipStep() {
    if (steps[this.currentStep].data.skipable) {
      let i = this.currentStep + 1;
      while (steps[i].data.dependsOn === i - 1) {
        i++;
      }
      this.currentStep = i;
      this.router.navigate([`/onboarding/${steps[i].path}`]);
    }
  }

  public previousStep() {
    this.location.back();
    this.currentStep--;
  }

  public nextStep() {
    this.router.navigate([`/onboarding/${steps[this.currentStep + 1].path}`]);
    this.currentStep++;
  }

  public completeOnboarding() {
    this.processAllStepData();
    this.onboardingCompleted.next();
  }

  public setStepState(step: number, data: StepState) {
    steps[step].data.state = data;
  }

  private async processAllStepData() {
    let username = steps[1].data.state?.data;
    if (!username) {
      this.goToStep(1);
      return;
    }
    let editUser = this.userService.editUserProfile({ username }).pipe(catchError((e) => {
      console.error(e);
      this.alert.error("Oups...", e.error || "An error occured, please retry");
      return of(null);
    }));
    let user = await firstValueFrom(editUser);
    if (!user) {
      this.goToStep(1);
      return;
    }
    this.router.navigate(['/explore']);
  }
}
