import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first, Subject } from 'rxjs';
import { Step, StepState } from '../../step/step.component';
import { StepData } from '../../services/onboarding.service';

@Component({
  selector: 'app-tutorial-step',
  templateUrl: './tutorial-step.component.html',
  styleUrls: ['./tutorial-step.component.scss'],
})
export class TutorialStepComponent implements Step, OnInit {

  constructor() { }

  confirm: Subject<void> = new Subject<void>();
  data?: StepData;
  processed = new EventEmitter<StepState>();
  
  ngOnInit() {
    this.confirm.pipe(first()).subscribe(() => {
      this.processed.emit({success: true});
    });
  }

}
