import { Component, OnInit } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'app-stars-message',
  templateUrl: './stars-message.component.html',
  styleUrls: ['./stars-message.component.scss'],
})
export class StarsMessageComponent extends Toast {

  action(event: MouseEvent) {
    event.stopPropagation();
    this.toastPackage.triggerAction(event);
    this.remove();
    return false;
  }
}
