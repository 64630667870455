import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {

  transform(value: number | null, args?: any): any {
    if (value === null) {
      return 'Loading';
    }

    
    if (args == "hms")
    {
      let hours = Math.floor(value / 3600000)
      let minutes = Math.floor((value - hours * 3600000) / 60000);
      let seconds = Math.floor((value - hours * 3600000 - minutes * 60000) / 1000);
      return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    }
    
    let minutes = Math.floor(value / 60000);
    let seconds = Math.floor((value - minutes * 60000) / 1000);

    return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
  }

}
