import { Injectable } from '@angular/core';
import { environment } from 'app/src/environments/environment';
import { StorageService } from './storage.service';
import { Socket } from 'ngx-socket-io';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class OverlieSocketService extends Socket {

  constructor(
    private platform: Platform,
    private localStorage: StorageService,
) {
    super((platform.is("android") || platform.is("ios")) && !platform.is("mobileweb") ? environment.wsConfigMobile : environment.wsConfig);
    localStorage.getItem('token').then((token) => this.ioSocket['auth'] = { token });
}
}
