@if (_isMobile)
{
  <ion-modal id="modal" #modal [initialBreakpoint]="modalBreakpoints[2]" [breakpoints]="modalBreakpoints">
    <ng-template>
      <div id="layout">
        <h1>My Posts</h1>
        <app-user-posts-list id="user-posts"></app-user-posts-list>
      </div>
    </ng-template>
  </ion-modal>
}
@else
{
  <ion-content id="main-container" class="ion-padding">
    <h1>My Posts</h1>
    <app-user-posts-list id="user-posts"></app-user-posts-list>
  </ion-content>
}

