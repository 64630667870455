import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { MapService } from 'app/src/app/data/map.service';
import { TileData, WorldPoint } from '@overlie/types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CommunityService } from 'app/src/app/data/community.service';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ConfirmationService } from 'app/src/app/utils/confirmation.service';

@Component({
  selector: 'app-community-list-entry',
  templateUrl: './community-list-entry.component.html',
  styleUrls: ['./community-list-entry.component.scss'],
})
export class CommunityListEntryComponent implements OnInit, AfterViewInit {

  @Input() communityId?: string;
  @Input() communityName?: string;
  @Input() communityImage?: string;
  @Input() nbNewContent?: number
  @Input() isBookmark?: boolean

  communityLeft?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  private tiles!: TileData[];
  private selectedTile?: WorldPoint;

  constructor(private mapService: MapService,
    private interactions: InteractionsService,
    private communityService: CommunityService,
    private platform: Platform,
    private router: Router,
    private confirmation: ConfirmationService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.mapService.mapData!.subscribe((md) => {
      if (md) {
        this.tiles = Array.from(md.tiles.values()).filter(tile => tile.communityId == this.communityId);
      }
    })
  }

  seeContent(e: MouseEvent) {
    e.stopPropagation();
    let i = Math.floor(Math.random() * this.tiles.length);
    this.selectedTile = this.tiles[i].position;
    this.mapService.seeContent(this.selectedTile)
    this.interactions.sendSignal(this.interactions.goButtonClicked, true)
  }

  seeCommunity() {
    this.router.navigate([`/explore/community/${this.communityId}`], { replaceUrl: this.platform.is('mobile') });
  }

  async leave(e: MouseEvent) {
    e.stopPropagation();
    if (!this.communityId) return;

    if (this.isBookmark) {
      this.communityService.remBookmark(this.communityId)
    }
    else {
      let communityInfos = this.communityService.communities.getValue().get(this.communityId);
      if (!communityInfos) return;
      let message: string = communityInfos.nbUsers == 1
        ? `It seems like you're the only one left in this community. It is at risk of being deleted if you leave it. Are you sure you want to leave ${communityInfos.name} ?`
        : `Are you sure you want to leave ${communityInfos.name} ?`;

      let res = await this.confirmation.confirm("Leave community", "", message);
      if (res) {
        this.communityService.leaveCommunity(this.communityId);
      }
    }
  }
}
