import { Injectable } from '@angular/core';
import { environment } from 'app/src/environments/environment';
import { BehaviorSubject, Observable, Subject, catchError, filter, first, map, of, tap } from 'rxjs';
import { OverlieHttpService } from './overliehttp.service';
import { Badge, ConnectionInfos, GoogleOAuthInfos, StandardError, UserMinimalInfos, UserPersonalInfos, UserPollChoice, WaitingForSponsoredCommunityCreation } from '@overlie/types';
import { AlertService } from '../utils/alert.service';
import { Router } from '@angular/router';
import { GenericOAuth2 } from "@capacitor-community/generic-oauth2";
import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular';
import { InteractionsService } from './interactions.service';
import { StarsAnimationService } from '../utils/stars-animation.service';


const defaultUserProfile: UserPersonalInfos = {
  id: '',
  username: '',
  email: '',
  stars: 0,
  badges: [],
  communities: [],
  bookmarks: [],
  tileDelay: 0,
  tileBoostRemainingTiles: 0,
  nextTileDate: 0,
  nextTileBoostDate: 0,
  gotDailyReward: false,
  isAdmin: false,
  isSubscribed: false,
  quitCommunityTickets: 0,
  waitingForSponsoredCommunityCreation: undefined };

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userProfile: BehaviorSubject<UserPersonalInfos> = new BehaviorSubject(defaultUserProfile);
  userPollChoices: BehaviorSubject<UserPollChoice[]> = new BehaviorSubject<UserPollChoice[]>([]);
  newPollChoice: Subject<UserPollChoice> = new Subject<UserPollChoice>()

  constructor(private http: OverlieHttpService,
    private router: Router,
    private alert: AlertService,
    private localStorage: StorageService,
    private platform: Platform,
    private starsAnim: StarsAnimationService
  ) { }


  public signupWithEmail(email: string, password: string, passwordConfirm: string): Observable<true | null> {
    return this.http.post<true>(`${environment.apiUrl}/auth/signup`, { email, password, passwordConfirm })
      .pipe(
        catchError((error) => {
          console.log(error);
          if (error.error) {
            this.alert.error('Signup failed', error.error);
          }
          return of(null);
        }),
        tap((ok) => {
          if (ok) {
            this.alert.success('Success', 'Account created successfully, please check your emails to confirm your address.');
          }
        })
      )
  }

  public confirmEmail(token: string): Observable<true | null> {
    return this.http.post<true>(`${environment.apiUrl}/auth/confirm`, { token })
      .pipe(
        catchError((error) => {
          console.log(error);
          if (error.error) {
            this.alert.error('Confirmation failed', error.error);
          }
          return of(null);
        }),
        tap((ok) => {
          if (ok) {
            this.alert.success('Success', 'Email confirmed, you can now login.');
            this.router.navigate(['/auth/signin']);
          }
        })
      )
  }

  public forgotPassword(email: string): Observable<true | null> {
    return this.http.post<true>(`${environment.apiUrl}/auth/forgot-password`, { email })
      .pipe(
        catchError((error) => {
          if (error.error) {
            this.alert.error('Reset failed', error.error);
          }
          return of(null);
        }),
        tap((ok) => {
          if (ok) this.alert.success('Request received', 'Please check your emails');
        })
      )
  }

  public resetPassword(token: string, password: string, passwordConfirm: string): Observable<true | null> {
    return this.http.post<true>(`${environment.apiUrl}/auth/reset`, { token, password, passwordConfirm })
      .pipe(
        catchError((error) => {
          if (error.error) {
            this.alert.error('Reset failed', error.error);
          }
          return of(null);
        }),
        tap((ok) => {
          if (ok) {
            this.alert.success('Password updated', 'You can now login.');
            this.router.navigate(['/auth/signin']);
          }
        })
      )
  }

  public loginWithEmail(email: string, password: string): Observable<ConnectionInfos | null> {
    return this.http.post<ConnectionInfos>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
        catchError((error) => {
          this.alert.error('Login failed', 'Invalid email or password');
          return of(null);
        }),
        tap((connectionInfos) => {
          if (connectionInfos) {
            this.alert.success('Success', 'You are now logged in');
            this.router.navigate(['/explore'], { replaceUrl: true });
            this.storeToken(connectionInfos);
        }
        })
      )
  }

  public async loginWithGoogle() {
    let height = 600;

    try {
      let authData = await GenericOAuth2.authenticate({
        authorizationBaseUrl: "https://accounts.google.com/o/oauth2/auth",
        accessTokenEndpoint: "https://www.googleapis.com/oauth2/v4/token",
        scope: "email profile",
        resourceUrl: "https://www.googleapis.com/userinfo/v2/me",
        web: {
          appId: environment.oauthAppId.google.web,
          responseType: "token",
          accessTokenEndpoint: "",
          redirectUrl: `${environment.appUrl}/auth/signin`,
          windowOptions: `height=${height},left=${(screen.width) / 2},top=${(screen.height - height) / 2}`
        },
        android: {
          appId: environment.oauthAppId.google.android,
          responseType: "code",
          redirectUrl: "net.overlie.twa:/auth/signin"
        },
        ios: {
          appId: environment.oauthAppId.google.ios,
          responseType: "code",
          redirectUrl: "fr.hoppersoft.overlie:/auth/signin"
        }
      });
      let connectionInfos: GoogleOAuthInfos = {
        id: authData.id,
        token: authData.access_token,
        email: authData.email
      }
      this.sendConnectionInfos(connectionInfos).pipe(first()).subscribe();
    }
    catch (error) {
      console.error("Google OAuth rejected", error);
    }
  }

  public logout() {
    return this.http.post(`${environment.apiUrl}/auth/logout`, {}).pipe(tap(() => {
      this.userProfile.next(defaultUserProfile);
      this.localStorage.removeItem('token');
      this.router.navigate(['/auth/signin']);
    }));
  }

  private sendConnectionInfos(infos: GoogleOAuthInfos): Observable<ConnectionInfos | null> {
    return this.http.postJson<ConnectionInfos>(`${environment.apiUrl}/auth/google/token`, infos)
      .pipe(tap((connectionInfos) => {
        if (connectionInfos) {
          this.alert.success('Success', 'You are now logged in');
          this.router.navigate(['/explore'], { replaceUrl: true });
          this.storeToken(connectionInfos);
        }
      }));
  }

  public refreshToken(): Observable<ConnectionInfos | null> {
    return this.http.post<ConnectionInfos>(`${environment.apiUrl}/auth/refresh-token`, {})
      .pipe(tap((connectionInfos) => {
        if (connectionInfos) {
          this.storeToken(connectionInfos);
        }
      })
      );
  }

  private storeToken(connectionInfos: ConnectionInfos): void {
    if (this.platform.is("mobile") && !this.platform.is("mobileweb")) {
      this.localStorage.setItem('token', connectionInfos.token);
    }
  }

  isAuthenticated(): Observable<boolean> {
    return this.getSelfProfile().pipe(
      map(userInfos => userInfos !== undefined),
      catchError(() => {
        return of(false);
      }));
  }

  isUserOnboarded(): Observable<boolean> {
    return this.getSelfProfile().pipe(
      map(userInfos => userInfos !== undefined && userInfos.username !== undefined && userInfos.username !== ''),
      catchError(() => {
        return of(false);
      }));
  }

  isAdmin(): Observable<boolean> {
    return this.getSelfProfile().pipe(
      map(userInfos => userInfos !== undefined && userInfos.isAdmin),
      catchError(() => {
        return of(false);
      }));
  }

  getSelfProfile(): Observable<UserPersonalInfos> {
    return this.http.get<UserPersonalInfos>(`${environment.apiUrl}/infos/user`).pipe(
      tap((userInfos) => {
        this.userProfile.next(userInfos);
        if (userInfos) {
          this.getUserPollChoices();
        }
      }))
  };

  handleDailyReward() {
    if (this.userProfile.getValue().gotDailyReward) return;
    this.localStorage.getItem('reward').then((reward) => {
      if (!reward || !this._checkGotRewardedToday(reward)) {
        const dailyRewardValue = 240;
        this.alert.dailyReward(dailyRewardValue).pipe(first()).subscribe((position) => {
          this.getDailyReward(dailyRewardValue, position).pipe(first()).subscribe();
        });
      }
    });
  }

  private _checkGotRewardedToday(reward: string): boolean {
    const time = parseInt(reward);
    const now = new Date();
    const previous = new Date(time);
    return now.getDate() === previous.getDate() 
      && now.getMonth() === previous.getMonth() 
      && now.getFullYear() === previous.getFullYear();
  }

  getDailyReward(amount: number, position: {x: number, y: number}): Observable<UserPersonalInfos | null> {
    return this.http.post<UserPersonalInfos>(`${environment.apiUrl}/infos/get-daily-reward`, {})
      .pipe(
        tap(async (userInfos) => {
          if (!userInfos) return;
          await this.starsAnim.animateGainStars(amount, position);
          this.userProfile.next(userInfos);
          this.localStorage.setItem('reward', Date.now().toString());
        }),
        catchError((e) => {
          console.error(e);
          return of(null);
        })
      );
  }

  getUserProfile(userId: string): Observable<UserMinimalInfos> {
    return this.http.get<UserMinimalInfos>(`${environment.apiUrl}/infos/user/${userId}`);
  }

  updateNextTileDate(value: number) {
    this.userProfile.next({ ...this.userProfile.value, nextTileDate: value });
  }

  updateTileBoostRemainingTilesValue(value: number) {
    this.userProfile.next({ ...this.userProfile.value, tileBoostRemainingTiles: value });
  }

  updateTileBoostRemainingTiles() {
    if (this.userProfile.value.tileBoostRemainingTiles > 0){
      this.updateTileBoostRemainingTilesValue(this.userProfile.value.tileBoostRemainingTiles - 1);
      if (this.userProfile.value.tileBoostRemainingTiles){
        this.userProfile.next({ ...this.userProfile.value, nextTileBoostDate: Date.now() + environment.tileBoostDelay });
      }
    }
  }

  activateTileBooster(): Observable<boolean | null> {
    return this.http.post<boolean>(`${environment.apiUrl}/infos/activate-tile-boost`, {})
      .pipe(tap((success: boolean | null) => {
        if (!success)
        {
          this.alert.error("Tile boost not yet available", "")
          return;
        }

        this.alert.success("Tile boost activated !", "Put 30 tiles without any delay !");
        this.updateTileBoostRemainingTilesValue(environment.tileBoostNbTiles);
      })
      )
  }

  updateUserCommunities(communityId: string) {
    this.userProfile.value.communities?.push(communityId)
  }

  answerPoll(pollId: string, index: number) {
    this.http.post<UserPollChoice>(`${environment.apiUrl}/infos/answer-poll`, { pollId: pollId, userId: this.userProfile.value.id, index: index })
      .pipe(catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(v => {
        if (!v) return;

        if (v instanceof StandardError) {
          this.alert.error("Couldn't register vote.", v.errorMsg)
          return;
        }

        let tmp = this.userPollChoices.value
        tmp.push(v)
        this.userPollChoices.next(tmp);
        this.newPollChoice.next(v)
      })
  }

  getUserPollChoices() {
    this.http.get<UserPollChoice[]>(`${environment.apiUrl}/infos/poll-choices`)
      .pipe(first(), catchError((e) => {
        return of(new StandardError(e.error))
      })).subscribe(value => {
        if (value instanceof StandardError) {
          this.alert.error("Error", value.errorMsg)
          return
        }

        this.userPollChoices.next(value)
      })
  }

  editUserProfile(edit: { avatar?: string, username?: string, bio?: string }): Observable<UserPersonalInfos | null> {
    return this.http.post<UserPersonalInfos | null>(`${environment.apiUrl}/infos/user/${this.userProfile.value.id}`, edit)
      .pipe(
        filter(v => v !== null),
        tap((userInfos) => {
          this.userProfile.next(userInfos!);
        }));
  }

  checkUsername(username: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/infos/username/check/${encodeURIComponent(username)}`)
  }

  getBadge(badgeId: number): Observable<Badge> {
    return this.http.get<Badge>(`${environment.apiUrl}/infos/badge/${badgeId}`).pipe(map(badge => {
      return Badge.fromJSON(badge);
    }));
  }

  reportUser(userId: string, reason: string) {
    this.http.post(`${environment.apiUrl}/infos/user/report/${userId}`, { reason: reason })
      .pipe(first()).subscribe((v: any) => {
        if (v.errorMsg)
        {
          this.alert.error("Oups...", v.errorMsg);
          return;
        }

        this.alert.success("Report sent", "The user has been reported.")
      });
  }
}
