import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageComponent } from './page/page.component';
import { MapViewComponent } from './views/map-view/map-view.component';
import { MapOverlayComponent } from './views/map-view/map-overlay/map-overlay.component';
import { AdminGuard, AuthGuard, NotOnboardedGuard, UnauthGuard } from './guards/auth.guard';
import { SettingsComponent } from './views/settings/settings.component';
import { PostsComponent } from './views/map-view/posts/posts.component';
import { CommunitiesComponent } from './views/map-view/communities/communities.component';
import { ContentViewComponent } from './views/content-view/content-view.component';
import { CommunityOverlayComponent } from './views/map-view/community-overlay/community-overlay.component';
import { StoreComponent } from './views/store/store.component';
import { ProfileComponent } from './views/map-view/profile/profile.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/explore',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    title: 'Auth',
    loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule),
    canActivate: [ UnauthGuard ]
  },
  {
    path: 'content',
    title: 'Content',
    component: ContentViewComponent,
    data: {
      fullscreen: true,
      showMenu: true
    }
  },
  {
    path: 'communities',
    redirectTo: '/explore/communities',
  },
  {
    path: 'explore',
    title: 'Explore',
    data: { fullscreen: true, showMenu: true },
    component: MapViewComponent,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: '',
        component: MapOverlayComponent
      },
      {
        path: 'communities',
        component: CommunitiesComponent
      },
      {
        path: 'community/:communityId',
        component: CommunityOverlayComponent
      },
      {
        path: 'user/:userId',
        component: ProfileComponent
      },
      {
        path: 'posts',
        component: PostsComponent
      }
    ]
  },
  {
    path: 'posts',
    title: 'My posts',
    component: PageComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'communities',
    title: 'Communities',
    component: PageComponent,
    canActivate: [ AuthGuard ]
  },
  // {
  //   path: 'store',
  //   title: 'Support us',
  //   data: { fullscreen: true, showMenu: true},
  //   component: StoreComponent,
  //   canActivate: [ AuthGuard ]
  // },
  {
    path: 'messages',
    title: 'Messages',
    component: PageComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'settings',
    title: 'Settings',
    data: { fullscreen: true, showMenu: true},
    component: SettingsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'profile',
    title: 'Profile',
    data: { fullscreen: true, showMenu: true, self: true },
    component: ProfileComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'onboarding',
    title: 'Onboarding',
    loadChildren: () => import('./views/onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [ NotOnboardedGuard ]
  },
  {
    path: 'admin',
    title: 'Admin',
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule),
    canActivate: [ AdminGuard ],
  },
  {
    path: '**',
    redirectTo: '/explore'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
