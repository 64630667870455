@if (showModal)
{
  <ion-modal id="modal" #modal [initialBreakpoint]="modalBreakpoints[2]" [breakpoints]="modalBreakpoints">
    <ng-container *ngIf="(user$ | async) !== null">
        <app-user-profile [type]="'user'" [user]="(user$ | async)" [canEdit]="isSelf"></app-user-profile>
    </ng-container>
  </ion-modal>
}
@else
{
  <div #mainContainer id="profile-main-container" class="ion-padding">
    <ng-container *ngIf="user$ | async as user">
        <app-user-profile [type]="'user'" [user]="user" [canEdit]="isSelf"></app-user-profile>
    </ng-container>
  </div>
}
