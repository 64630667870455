import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { UserService } from 'app/src/app/data/user.service';
import { AlertService } from 'app/src/app/utils/alert.service';
import { catchError, first, Subject, Subscription } from 'rxjs';
import { OnboardingService, StepData } from '../../services/onboarding.service';
import { Step, StepState } from '../../step/step.component';

@Component({
  selector: 'app-username-step',
  templateUrl: './username-step.component.html',
  styleUrls: ['./username-step.component.scss'],
})
export class UsernameStepComponent implements Step, OnInit, OnDestroy {

  constructor(private userService: UserService, private alert: AlertService, private onboarding: OnboardingService) { }

  @ViewChild('usernameInput') usernameInput?: IonInput;
  confirm: Subject<void> = new Subject<void>();
  data?: StepData;
  processed = new EventEmitter<StepState>();

  confirmSubscription?: Subscription;

  ngOnInit() {
    this.confirmSubscription = this.confirm.subscribe(() => {
      this.checkUsername();
    });
  }

  ngOnDestroy() {
    this.confirmSubscription?.unsubscribe();
  }

  checkUsername() {
    let username = this.usernameInput?.value?.toString();
    if (!username) {
      this.alert.warning("Oups...", "Username is required");
      this.processed.emit({success: false});
      return;
    }
    this.userService.checkUsername(username).pipe(first(), catchError((e) => {
      this.alert.error("Oups...", e.error);
      this.processed.emit({success: false});
      return [];
    })).subscribe(() => {
      this.processed.emit({success: true, data: username});
    });
  }
}
