<div id="main">
    <div class="layout">
        <div id="title-container" [ngStyle]="getTitleContainerStyle()">
            <div class="fade title-container-elements">
                @if(community && community.name && focused) {
                <div id="community-name-container">
                    <ion-icon class="prefix-icon" name="people-outline"></ion-icon>
                    <p id="community-name">{{community.name}}</p>
                </div>

                @if (showJoinCommunityButton && focused) {
                <app-action-button class="action-button" text="Join" type="tertiary" [fontSize]="1"
                    (click)="joinCommunity()"> </app-action-button>
                } @else if (!showJoinCommunityButton && focused) {
                <app-action-button class="action-button" text="Leave" type="tertiary" [fontSize]="1"
                    (click)="leaveCommunity()"></app-action-button>
                }
                }
            </div>
        </div>
        <div id="content-container" [ngStyle]="getContentContainerStyle()">

            @if (content) {
            @switch (content.type) {
            @case ("image") {
            <img id="content-background" [src]="payloadUrl">
            <img [src]="payloadUrl" [ngStyle]="getContentStyle()">
            }
            @case ("video") {
            <video id="content-background" [src]="payloadUrl" [autoplay]="autoPlay" playsinline loop preload="none"
                [muted]="true"></video>
            <app-video #video [sourceUrl]="payloadUrl" [autoPlay]="autoPlay" preload="none"
                [ngStyle]="getContentStyle()"></app-video>
            }
            @case ("GIF") {
            <img id="content-background" [src]="content.gifUrl">
            <ion-img [src]="content.gifUrl" [ngStyle]="getContentStyle()"></ion-img>
            }
            @case ("text") {
            <div [innerHTML]="content.text | parseUrls">
            </div>
            }
            @default {
            <p>No content here</p>
            }
            }
            }
            @else {
            <ion-spinner name="crescent"></ion-spinner>
            }
        </div>

        @if (content && content.type) {
        <div id="action-buttons" [ngStyle]="getMetadatasStyle()">
            <div class="gap-m">
                <app-overbucks [amount]="content.starsDonated" [iconSize]="iconSize" [fontSize]="fontSize"
                    [shadow]="true" [id]="content.id" [contentId]="content.contentId"></app-overbucks>
                <app-lifetimelabel [iconSize]="iconSize" [fontSize]="fontSize" [protectionEndDate]="content.protectionEndDate"
                    [overbucks]="(content.starsDonated || 0) + (content.starsInvested! || 0)"></app-lifetimelabel>
                <app-share-button [authorUsername]="authorUserame" [description]="content.text" [url]="url"
                    [ngStyle]="shareBtnStyle()" [iconSize]="iconSize"></app-share-button>
                <ion-button class="menu-button" fill="clear" (click)="presentPopover($event)">
                    <ion-icon name="ellipsis-vertical"></ion-icon>
                </ion-button>
            </div>
        </div>
        }

        <div id="caption-container" [ngStyle]="getCaptionContainerStyle()">
            @if (authorUserame && focused) {
            <div id="username-container" class="fade">
                <ion-icon class="prefix-icon" name="person-circle-outline"></ion-icon>
                <p id="caption">{{authorUserame}}</p>
            </div>
            @if (content && content.type !== "text") {
            <p id="description" class="fade">{{content.text}}</p>
            }
            }
        </div>
    </div>
</div>