<div class="layout">
  @if (data) {
    <h1>Tutorial {{ data.additionalData.tutorial }}/4</h1>
    
    @switch (data.additionalData.tutorial) {
      @case (1) {
        <h4>Explore the map and discover new communities</h4>
        <img class="illustation-image" src="/assets/onboarding/communities.png" alt="">
      }
      @case (2) {
        <h4>Enter view mode to enjoy communities content</h4>
        <img class="illustation-image" src="/assets/onboarding/content.png" alt="">
        <p>Swipe in any direction ! Give a star to increase content's lifetime</p>
      }
      @case (3) {
        <h4>Join or create a community to be able to post content inside its territory</h4>
        <img class="illustation-image" src="/assets/onboarding/post.png" alt="">
        <p>Post text, image, video or even GIFs !</p>
      }
      @case (4) {
        <h4>Expand your community territory and dominate the map !</h4>
        <img class="illustation-image" src="/assets/onboarding/map.png" alt="">
      }
    }
    }
</div>
