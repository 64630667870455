import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'parseUrls'
})
export class ParseUrlsPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }
  transform(value: string | undefined, ...args: unknown[]): SafeHtml {
    if (!value) return "";

    const regexString = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
    return this.sanitizer.bypassSecurityTrustHtml(value.replace(regexString, (substring, _) => {
      return `<a href="${substring}">${substring}</a>`;
    }));
  }
}