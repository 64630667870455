<div id="listLayout" #listLayout>
@for (contentsObj of contentsByCommunity; track $index)
{
  @if (contentsObj.contents.length > 0)
  {
    <button id="header-button" (click)="HideShow(contentsObj.community.name)">
      <div id="title-layout">
        <ion-icon id="header-icon" name="remove-outline"></ion-icon>
        <p id="header-text">{{ contentsObj.community.name }}</p>
      </div>
      <div id="nb-posts-layout">
        <p id="nb-posts">{{ contentsObj.contents.length }}</p>
      </div>
    </button>
    @if (contentsObj.visible)
    {
      @for (content of contentsObj.contents; track content.id)
      {
        <div id="post-container">

          @switch (content.type)
          {
            @case("video")
            {
              <app-video id="content"
                preload="auto"
                [autoPlay]="false"
                [sourceUrl]="api_url + '/contentpayload?coordinates=' + content.id">
              </app-video>
            }
            @case("image")
            {
              <app-image id="content" [sourceUrl]="api_url + '/contentpayload?coordinates=' + content.id"/>
            }
            @case ("GIF")
            {
              <app-image id="content" [sourceUrl]="content.gifUrl"/>
            }
          }
          <p id="post-caption">{{ content.text }}</p>
          <app-go-button id="go" [coordinates]="content.id"></app-go-button>
          <app-sponsor-button id="sponsor" [contentId]="content.id" [isSponsoring]="true"></app-sponsor-button>
          <app-overbucks [amount]="content.starsDonated"></app-overbucks>
          <app-lifetimelabel id="lifetime" [protectionEndDate]="content.protectionEndDate" [overbucks]="(content.starsDonated || 0) + (content.starsInvested || 0)"></app-lifetimelabel>
          <app-share-button [authorUsername]="username" [description]="content.text" [url]="appUrl+'/content?id='+content.id" id="share-button"></app-share-button>
        </div>
        <div id="separator"></div>
      }
    }
  }
}
</div>