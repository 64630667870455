import { Injectable } from '@angular/core';
import { AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(private alertController: AlertController) { }

  public async confirm(header: string, subHeader: string, message: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        this.alertController.create({
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => resolve(false)
            },
            {
              text: 'OK',
              role: 'confirm',
              handler: () => resolve(true)
            },
          ],
          header: header,
          subHeader: subHeader,
          message: message,
        }).then(alert => alert.present())
      }
    );
  }

  public async select(header: string, subHeader: string, message: string, buttons: {text: string, value: string}[]): Promise<string> {
    return new Promise<any>(
      (resolve, reject) => {
        let alertButtons = buttons.map(b => {
          return {
            text: b.text,
            role: 'confirm',
            handler: () => resolve(b.value)
          }
        });
        alertButtons.push({
          text: 'Cancel',
          role: 'cancel',
          handler: () => resolve(false)
        });
        this.alertController.create({
          buttons: alertButtons,
          header: header,
          subHeader: subHeader,
          message: message,
        }).then(alert => alert.present())
      })
  }
}
