import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject, distinctUntilChanged, filter, map, scan, startWith, switchMap, take, takeWhile, tap, timer } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InteractionsService {
  constructor(private userService: UserService) { }

  contentBackgroundColor = new ReplaySubject<string>();
  contentBackgroundColor$ = this.contentBackgroundColor.asObservable();

  addTileButtonClicked = new Subject<boolean>();
  addTileButtonClicked$ = this.addTileButtonClicked.asObservable();

  viewTile = new Subject<boolean>();
  viewTile$ = this.viewTile.asObservable();

  createCommunityButtonClicked = new Subject<boolean>();
  createCommunityButtonClicked$ = this.createCommunityButtonClicked.asObservable();

  createSponsoredCommunityButtonClicked = new Subject<boolean>();
  createSponsoredCommunityButtonClicked$ = this.createSponsoredCommunityButtonClicked.asObservable();

  goButtonClicked = new Subject<boolean>();
  goButtonClicked$ = this.goButtonClicked.asObservable();

  timer = new BehaviorSubject<number | null>(null);
  timer$ = this.timer.asObservable();

  tileBoostTimer = new BehaviorSubject<number | null>(null);
  tileBoostTimer$ = this.tileBoostTimer.asObservable();

  tileAvailable$ = this.timer$.pipe(map(value => value === 0));
  tileBoostAvailable$ = this.tileBoostTimer$.pipe(filter(v => v != null), map(value => value === 0));
  tileBoostRemainingTiles$ = this.userService.userProfile.pipe(map(profile => {
    return profile.tileBoostRemainingTiles;
  }));

  sendSignal<T>(reference: ReplaySubject<T> | BehaviorSubject<T> | Subject<T>, value: any) {
    reference.next(value);
  }

  initTimers() {
    this.userService.userProfile.pipe(
      map(userProfile => userProfile.nextTileDate),
      distinctUntilChanged(),
      switchMap(nextTileDate => timer(0, 1000).pipe(
        startWith(0),
        map(() => Math.max(nextTileDate - Date.now(), 0))
      ))
    ).subscribe(newValue => this.timer.next(newValue));

    this.userService.userProfile.pipe(
      map(userProfile => userProfile.nextTileBoostDate ?? 0),
      distinctUntilChanged(),
      switchMap(nextTileBoostDate => timer(0, 1000).pipe(
        startWith(0),
        map(() => Math.max(nextTileBoostDate - Date.now(), 0))
      ))
    ).subscribe(newValue => this.tileBoostTimer.next(newValue));
  }
}
