import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, first, of } from 'rxjs';
import { ContentType, ContentMetadata, PersonalContentMetadata, IContentPOST, ContentMetadataWithNearby, SwipeDirection } from '@overlie/types';
import { environment } from 'app/src/environments/environment';
import { UserService } from './user.service';
import { OverlieHttpService } from './overliehttp.service';
import { AlertService } from '../utils/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ContentsService {

  contentSponsorised: Subject<{id: string, amount: number}> = new Subject<{id: string, amount: number}>();
  swipeDirection: SwipeDirection | null = null;
  previousContent: ContentMetadata | null = null;
  currentContent: ContentMetadata | null = null;
  
  constructor(private httpClient: OverlieHttpService,
    private userService: UserService,
    private alert: AlertService) { }

  getContentsForUser(userId: string): Observable<PersonalContentMetadata[]> {
    return this.httpClient.get<PersonalContentMetadata[]>(`${environment.apiUrl}/content?userId=${userId}`)
  }

  getContent(contentId: string): Observable<ContentMetadata> {
    return this.httpClient.get<ContentMetadata>(`${environment.apiUrl}/content?coordinates=${contentId}`)
      .pipe(
        catchError((e) => {
          console.error(e)
          return new Observable<ContentMetadata>();
        })) as Observable<ContentMetadata>;
  }

  getContentWithNearby(contentId: string): Observable<ContentMetadataWithNearby | undefined> {
    return this.httpClient.get<ContentMetadataWithNearby>(`${environment.apiUrl}/content?coordinates=${contentId}&nearby=true`)
      .pipe(
        catchError(() => {
          return of(undefined);
        })) as Observable<ContentMetadataWithNearby>;
  }

  getContentNearbyContent(contentId: string): Observable<ContentMetadata[]> {
    return this.httpClient.get<ContentMetadata[]>(`${environment.apiUrl}/content/nearby?coordinates=${contentId}`)
  }

  getContentPayloadURL(contentId: string): string {
    return `${environment.apiUrl}/contentpayload?coordinates=${contentId}`
  }

  sponsorContent(contentId: string, amount: number, isSponso: boolean) {
      let userProfile = this.userService.userProfile.value;

      if (userProfile.stars >= amount)
      {
        this.httpClient.put(`${environment.apiUrl}/content/sponsor`, { contentId: contentId, userId: userProfile.id, amount: amount, isSponso: isSponso })
        .pipe(first()).subscribe((v: any) => {

          if (v.errorMsg)
          {
            this.alert.error("Oups...", v.errorMsg);
            return
          }

          this.contentSponsorised.next({id: contentId, amount: amount});
        });
      }
      else
      {
        this.alert.error("Not enough stars", "You don't have enough stars !")
      }
  }

  reportContent(contentId: string, reason: string) {
    this.httpClient.post(`${environment.apiUrl}/content/report`, { coordinates: contentId, reason: reason })
      .pipe(first()).subscribe((v: any) => {
        if (v.errorMsg)
        {
          this.alert.error("Oups...", v.errorMsg);
          return
        }

        this.alert.success("Report sent", "The content has been reported.")
      });
  }

  postContent(content: IContentPOST): Observable<ContentMetadata | null> {
    let formData = new FormData();
    formData.append('text', encodeURI(content.text));
    formData.append('communityId', content.communityId);
    formData.append('type', content.contentType);
    if (content.sponsorisationAmount) {
      formData.append('sponsorisationAmount', content.sponsorisationAmount?.toString());
    }
    if (content.contentType === 'GIF')
      formData.append('gifUrl', content.gifUrl);
    if (content.contentType === ContentType.Image || content.contentType === ContentType.Video) {
      formData.append('contentfile', content.media);
    }

    return this.httpClient.post<ContentMetadata>(`${environment.apiUrl}/contentpayload?coordinates=${content.coordinates.join(',')}`,
      formData, true);
  }
}
