import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonModal, Platform } from '@ionic/angular';
import { CommunityService } from 'app/src/app/data/community.service';
import { InteractionsService } from 'app/src/app/data/interactions.service';
import { Observable, Subscription, filter, first, map, skip } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss'],
})
export class CommunitiesComponent implements OnInit, AfterViewInit, OnDestroy {

  isMobile?: boolean

  @ViewChild('modal') modal?: IonModal;

  modalBreakpoints = [0, 0.5, 1];

  routerSubscription?: Subscription;

  constructor(private platform: Platform,
              private communityService: CommunityService,
              private interactionService: InteractionsService,
              private router: Router) { }

  myCommunities$: Observable<string[]> = this.communityService.myCommunities$.pipe(
    map(communityIds => communityIds.map(communityId => this.communityService.getCommunityName(communityId) || ''))
  );

  ngOnInit() {
    this.isMobile = this.platform.is('mobile');
  }

  ngAfterViewInit(): void {
    if (this.isMobile)
    {
      this.modal?.present();
      this.interactionService.goButtonClicked$.pipe(first()).subscribe(_ => this.modal?.dismiss())
      this.modal?.ionModalDidDismiss.pipe(first()).subscribe((event) => {
        if (event.detail.data === true) return;
        this.router.navigate(['/explore'], {replaceUrl: true});
      });
      this.routerSubscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd), skip(1)).subscribe(() => {
        this.modal?.dismiss(true);
      });
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }
}
