import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { OverlieHttpService } from './overliehttp.service';
import { environment } from 'app/src/environments/environment';
import { Platform } from '@ionic/angular';
import { OnboardingService } from '../views/onboarding/services/onboarding.service';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private overlieHttp: OverlieHttpService,
    private platform: Platform,
    private onboarding: OnboardingService)
  {
    if ((platform.is("ios") || platform.is("android")) && !platform.is("mobileweb"))
      {
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === 'granted') {
            console.log("Push permission granted !!")
            PushNotifications.register();
          } else {
            console.log("Push permission denied !!")
          }})
    
        PushNotifications.addListener('registration', (token: Token) => {
          overlieHttp.post<any>(`${environment.apiUrl}/push/subscribe`, { token: token.value })
        });
      }
  }
}
