import { Component, Input, OnInit, booleanAttribute, numberAttribute } from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent  implements OnInit {

  constructor() { }

  @Input() text?: string;
  @Input({ transform: booleanAttribute }) disabled?: boolean;
  @Input({ transform: booleanAttribute }) shadow?: boolean;
  @Input({ transform: booleanAttribute }) loading?: boolean;
  @Input() fontSize?: number;
  @Input({ required: true }) type!: 'primary' | 'secondary' | 'tertiary' | 'icon';
  @Input() icon?: string;
  ngOnInit() {}

}
