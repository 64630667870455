import { Injectable } from '@angular/core';
import { LocalNotificationDescriptor, LocalNotifications } from '@capacitor/local-notifications'
import { OnboardingService } from '../views/onboarding/services/onboarding.service';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationsService {

  private scheduledNotifs: LocalNotificationDescriptor[] = [];

  constructor(private onboarding: OnboardingService)
  {
    LocalNotifications.requestPermissions();
  }

  async scheduleTileNotification(title: string, body: string, delayMS: number, repeat: boolean)
  {
    if ((await LocalNotifications.checkPermissions()).display == 'granted')
    {
      LocalNotifications.cancel({ notifications: this.scheduledNotifs })
      let res = await LocalNotifications.schedule({ notifications: [
        {
          id: 0,
          title: title,
          body: body,
          schedule: { repeats: repeat, at: new Date(Date.now() + delayMS ) }
        }
      ]})
      this.scheduledNotifs = res.notifications ?? [];
    }
  }
}
