import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { CapacitorHttp } from '@capacitor/core';
import { environment } from 'app/src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class OverlieHttpService {

  isNative: boolean;
  isNativeAndroid: boolean;
  isNativeIOS: boolean;

  constructor(private angularHttpClient: HttpClient, private platform: Platform, private localStorage: StorageService) {
    this.isNative = this.platform.is("mobile") && !this.platform.is("mobileweb");
    this.isNativeAndroid = this.platform.is("android") && !this.platform.is("mobileweb");
    this.isNativeIOS = this.platform.is("ios") && !this.platform.is("mobileweb");
  }

  get<Type>(url: string): Observable<Type> {
    const handleRequest = (token: string | null) => {
      if (this.isNative) {
        CapacitorHttp.get({
          url: url,
          headers: { 'Authorization': `Bearer ${token ?? ''}`  },
          webFetchExtra: { credentials: "include" },
        }).then(response => {
          if (response.status == 200) {
            result.next(response.data);
          }
          else {
            result.error('HttpRequestFail')
          }
        });
      } else {
        this.angularHttpClient.get<Type>(url, {
          ...environment.httpOptions,
          headers: new HttpHeaders({ 'Authorization': `Bearer ${token ?? ''}`  })
        }).subscribe({
          next: (value) => result.next(value),
          error: (error) => result.error(error),
          complete: () => result.complete()
        });
      }
    };

    let result: Subject<Type> = new Subject<Type>();
    this.localStorage.getItem('token').then(handleRequest);
    return result;
  }

  post<Type>(url: string, body: any, complex: boolean = false): Observable<Type | null> {
    const handleRequest = (token: string | null) => {
      if (this.isNative) {
        let headers: HeadersInit = {
          'Authorization': `Bearer ${token ?? ''}`,
        }
        if ((this.isNativeAndroid && !complex) || this.isNativeIOS)
        {
          headers['Content-Type'] = 'application/json'
        }
        fetch(url, {
          method: "POST",
          headers: headers,
          body: body
        }).then(async response => {
          if (response.status == 200) {
            result.next(await response.json());
          } else {
            result.error(response);
          }
        });
      } else {
        this.angularHttpClient.post<Type>(url, body, {
          ...environment.httpOptions,
          headers: new HttpHeaders({ 'Authorization': `Bearer ${token ?? ''}`  })
        }).subscribe({
          next: (value) => result.next(value),
          error: (error) => result.error(error),
          complete: () => result.complete()
        });
      }
    };
    let result: Subject<Type | null> = new Subject<Type | null>();
    this.localStorage.getItem('token').then(handleRequest);
    return result;
  }

  postJson<Type>(url: string, body: any): Observable<Type | null> {
    const handleRequest = (token: string | null) => {
      if (this.isNative) {
        const options = {
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token ?? ''}` 
          },
          data: JSON.stringify(body)
        };

        CapacitorHttp.post(options).then(response => {
          if (response.status == 200) {
            result.next(response.data);
          }
          else {
            result.error(response)
          }
        })
      }
      else {
        this.angularHttpClient.post<Type>(url, body, {
          ...environment.httpOptions,
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token ?? ''}` 
          })
        }).subscribe({
          next: (value) => result.next(value),
          error: (error) => result.error(error),
          complete: () => result.complete()
        });
      }
    }

    let result: Subject<Type | null> = new Subject<Type | null>();
    this.localStorage.getItem('token').then(handleRequest);
    return result;
  }

  put<Type>(url: string, body: any): Observable<Type | null> {
    const handleRequest = (token: string | null) => {
      if (this.isNative) {
        let headers: HeadersInit = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token ?? ''}`
        }

        fetch(url, {
          method: "PUT",
          headers: headers,
          body: body
        }).then(async response => {
          if (response.status == 200) {
            result.next(await response.json());
          }
          else {
            result.error(response)
          }
        });
      } else {
        this.angularHttpClient.put<Type>(url, body, {
          ...environment.httpOptions,
          headers: new HttpHeaders({ 'Authorization': `Bearer ${token ?? ''}`  })
        }).subscribe({
          next: (value) => result.next(value),
          error: (error) => result.error(error),
          complete: () => result.complete()
        });
      }
    }

    let result: Subject<Type | null> = new Subject<Type | null>();
    this.localStorage.getItem('token').then(handleRequest);
    return result;
  }
}
