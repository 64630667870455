import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject, take, tap } from 'rxjs';
import { CommunityInfos, LineStringFeatureCollection, LineStringFeature, SwipeDirection, TagLink, TileData, TileGeometry, WorldPoint, MapState } from '@overlie/types';
import { environment } from 'app/src/environments/environment';
import { CommunityService } from './community.service';
import { LngLatLike, Map as OverlieMap, Marker, Source } from 'maplibre-gl';
import { CommunityPopupComponent } from '../components/communities/community-popup/community-popup.component';
import { OverlieHttpService } from './overliehttp.service';
import { MapUtils } from '../utils/MapUtils';
import { along } from '@turf/turf';
import { InteractionsService } from './interactions.service';
import { StorageService } from './storage.service';

export type MapData = {
  communities: Map<string, CommunityInfos>;
  borders: WorldPoint[][][];
  tiles: Map<string, TileData>;
}

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(
    private http: OverlieHttpService,
    private interactions: InteractionsService,
    private communityService: CommunityService, 
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private zone: NgZone,
    private localStorage: StorageService) { }

  mapData: BehaviorSubject<MapData | undefined> = new BehaviorSubject<MapData | undefined>(undefined);
  forceMapSync$: Subject<void> = new Subject();
  focusedCoordinates?: BehaviorSubject<[number, number] | undefined> = new BehaviorSubject<[number, number] | undefined>(undefined);
  focusedArea?: BehaviorSubject<[WorldPoint, WorldPoint] | undefined> = new BehaviorSubject<[WorldPoint, WorldPoint] | undefined>(undefined);
  selectedTile: BehaviorSubject<TileGeometry | undefined> = new BehaviorSubject<TileGeometry | undefined>(undefined);
  popupRefs: ComponentRef<CommunityPopupComponent>[] = [];
  communityPopups: Marker[] = [];
  endFocused: Subject<boolean | undefined> = new Subject<boolean | undefined>();

  tagLinksFeatures: LineStringFeatureCollection = {
    type: "FeatureCollection",
    features: []
  }

  selectedTagLink: BehaviorSubject<TagLink | undefined> = new BehaviorSubject<TagLink | undefined>(undefined);

  public getMapData(forceResync: boolean = false): Observable<MapData> {
    return this.http.get<MapData>(`${environment.apiUrl}/infos/map`).pipe(
      tap((data: MapData) => {
        if (data) {
          this.mapData?.next({
            borders: data.borders,
            tiles: new Map(data.tiles),
            communities: new Map(data.communities)
          });

          if (forceResync)
          {
            this.forceMapSync$.next();
          }
        }
      })
    );
  }

  public addTileToMap(tile: TileData) {
    if (!this.mapData || !this.mapData.value || !this.mapData.value.tiles)
      return;
    this.mapData.value.tiles.set(MapUtils.worldPointToUniqueString(tile.position), tile);
    this.mapData.next(this.mapData.value);
  }

  public addTilesToMap(tiles: TileData[]) {
    if (!this.mapData || !this.mapData.value || !this.mapData.value.tiles)
      return;
    for (let tile of tiles)
    {
      this.mapData.value.tiles.set(MapUtils.worldPointToUniqueString(tile.position), tile);
    }
    this.mapData.next(this.mapData.value);
  }

  public goToCoordinates(coordinates: WorldPoint) {
    this.focusedCoordinates!.next(coordinates)
  }

  public seeContent(coordinates: WorldPoint = [200, 200])
  {
      if (coordinates[0] != 200)
      {
        this.selectNewTile(coordinates);
      }
    
      if (this.selectedTile.value)
      {
        this.goToCoordinates(this.selectedTile.value[0]);
      }

      this.endFocused!.pipe(take(1)).subscribe((value) => {
        if (value) {
          this.goToContent()
        }
      });
  }

  public goToContent() {
    if (!this.selectedTile) return;

    this.interactions.sendSignal<boolean>(this.interactions.viewTile, true);
  }

  public focusArea(area: [WorldPoint, WorldPoint])
  {
    this.focusedArea!.next(area)
  }

  public focusCommunity(communityId: string) {
    let md = this.mapData?.getValue();
    if (!md) return;
    this.focusedArea!.next(MapUtils.getCommunityBounds(Array.from(md.tiles.values()), communityId));
  }

  public getCoordinatesFromId(id: string): WorldPoint {
    let coords = id.split(',');
    return [parseFloat(coords[0]), parseFloat(coords[1])];
  }

  public getNearbyIds(id: string): Record<SwipeDirection, string> {
    let coords = this.getCoordinatesFromId(id);
    let top = [coords[0], coords[1] + MapUtils.TILES_OFFSET_LAT];
    let bottom = [coords[0], coords[1] - MapUtils.TILES_OFFSET_LAT];
    let right = [coords[0] + MapUtils.TILES_OFFSET_LONG, coords[1]];
    let left = [coords[0] - MapUtils.TILES_OFFSET_LONG, coords[1]];
    return {
      'top': top.join(','),
      'bottom': bottom.join(','),
      'right': right.join(','),
      'left': left.join(',')
    };
  }

  public async clearCommunityPopups() {
    if (!this.communityPopups) return;
    for (let popup of this.communityPopups) {
      popup.remove();
    }
    for (let popupRef of this.popupRefs) {
      popupRef.destroy();
    }
    this.communityPopups = [];
    this.popupRefs = [];
  }

  public async displayCommunityPopup(communityId: string, lngLat: LngLatLike, map: OverlieMap, unique: boolean) {
    if (unique) {
      this.clearCommunityPopups();
    }

    this.zone.runOutsideAngular(() => {
      const popupElement = document.createElement('div');

      this.communityPopups.push(new Marker({ element: popupElement, anchor: 'bottom' })
        .setOffset([0, -20])
        .setLngLat(lngLat)
        .addTo(map));
      this.loadPopup(popupElement, communityId);
    });
  }

  private loadPopup(container: HTMLElement, communityId: string) {
    const factory = this.resolver.resolveComponentFactory(CommunityPopupComponent);
    let popupRef = factory.create(this.injector)
    this.popupRefs.push(popupRef);

    this.appRef.attachView(popupRef.hostView);
    container.appendChild((popupRef.hostView as any).rootNodes[0]);

    let communityInfos = this.communityService.communities.value.get(communityId);
    if (communityInfos != null) {
      popupRef.instance.communitiesInfos = communityInfos;
    }
  }

  public selectNewTile(wp: WorldPoint) {
    this.selectedTile?.next(MapUtils.worldPointToTileGeometry(wp));
  }

  /**
 * This function checks if the selected tile is empty, i.e. if there is no tile already placed on it.
 * @returns true if the tile is empty, false otherwise
 */
  public isTileEmpty(wp: WorldPoint): boolean {
    let legalWp = MapUtils.makeCoordLegal(wp)
    if (this.mapData.value)
    {
      return !this.mapData.value?.tiles.has(MapUtils.worldPointToUniqueString(legalWp));
    }

    return false;
  }
  
  
  /**
   * This function checks if the selected tile is clickable, i.e. if a user's community owns an adjacent tile and the tile itself is not owned by a user's community.
   * @returns true if the tile is clickable, false otherwise
   */
  public isTileClickable(wp: WorldPoint): boolean {
    let legalWp = MapUtils.makeCoordLegal(wp)
    if (!this.mapData.value) return false;

    let tileCommunity = this.mapData.value?.tiles.get(MapUtils.worldPointToUniqueString(legalWp))?.communityId
    if (tileCommunity && this.communityService.myCommunities?.value.includes(tileCommunity))
    {
      return false;
    }

    let newIterable = [...this.mapData.value.tiles].filter(([_, data]) => this.communityService.myCommunities?.value.includes(data.communityId))
    let communityFeatures: Map<string, TileData> = new Map(newIterable);
    
    return communityFeatures.has(MapUtils.worldPointToUniqueString([legalWp[0] + MapUtils.TILES_OFFSET_LONG, legalWp[1]]))
        || communityFeatures.has(MapUtils.worldPointToUniqueString([legalWp[0] - MapUtils.TILES_OFFSET_LONG, legalWp[1]]))
        || communityFeatures.has(MapUtils.worldPointToUniqueString([legalWp[0], legalWp[1] + MapUtils.TILES_OFFSET_LAT]))
        || communityFeatures.has(MapUtils.worldPointToUniqueString([legalWp[0], legalWp[1] - MapUtils.TILES_OFFSET_LAT]))
  }
  
  /**
   * This function checks if the selected tile can host a post (belongs to a user's community and is free).
   */
  public isTileCanBePosted(wp: WorldPoint): boolean {
    let legalWp = MapUtils.makeCoordLegal(wp)

    if (this.mapData.value)
    {
      let selectedTileData = this.mapData.value.tiles.get(MapUtils.worldPointToUniqueString(legalWp))
      if (selectedTileData)
      {
        return this.communityService.myCommunities.value.includes(selectedTileData.communityId) 
          && (selectedTileData.postDate == -1 
            || MapUtils.calculateProtectionInMS(selectedTileData.postDate) < 0);
      }
    }

    return false;
  }

  public getTileCommunity(wp: WorldPoint): CommunityInfos | undefined
  {
    if (this.mapData.value)
    {
      let legalWp = MapUtils.makeCoordLegal(wp)

      let communityId = this.mapData.value.tiles.get(MapUtils.worldPointToUniqueString(legalWp))?.communityId
      if (communityId)
      {
        return this.mapData.value.communities.get(communityId);
      }
    }

    return undefined
  }

  private initTagLinks(mapRef: OverlieMap) {
    mapRef.addSource('tag-links', {
      'type': 'geojson',
      lineMetrics: true,
      'data': this.tagLinksFeatures
    });

    mapRef.addLayer({
      'id': 'tag-links',
      'type': 'line',
      'source': 'tag-links',
      'paint': {
        // "line-dasharray": [5, 2],
        "line-color": ['get', 'color'],
        "line-width": 2,
        // 'line-gradient': [
        //   'interpolate',
        //   ['linear'],
        //   ['line-progress'],
        //   0,
        //   '#2400FF',
        //   0.33,
        //   '#B100C1',
        //   0.66,
        //   '#C40039',
        //   1,
        //   '#C37500'
        // ]
      },
      'layout': {
      }
    });
    mapRef.moveLayer('tag-links', 'communities-names');
  }

  public async clearTagLinks(mapRef?: OverlieMap) {
    if (!mapRef) return;
    let tagLinks = mapRef.getSource('tag-links');
    if (!tagLinks) return;
    this.tagLinksFeatures.features = [];
    //@ts-ignore
    tagLinks.setData(this.tagLinksFeatures);
  }

  public async traceTagLink(point1: WorldPoint, point2: WorldPoint, mapRef: OverlieMap, color?: string) {
    let tagLinks = mapRef.getSource('tag-links');
    if (!tagLinks) {
      this.initTagLinks(mapRef);
      tagLinks = mapRef.getSource('tag-links')!;
    }
    this.tagLinksFeatures.features.push({
      type: "Feature",
      id: Math.floor(Math.random() * 10000).toString(),
      properties:
      {
        color: color || '#ffffff',
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [point1[0] + MapUtils.APPROXIMATE_CENTER_OFFSET, point1[1] + MapUtils.APPROXIMATE_CENTER_OFFSET],
          [point2[0] + MapUtils.APPROXIMATE_CENTER_OFFSET, point2[1] + MapUtils.APPROXIMATE_CENTER_OFFSET],
        ]
      }
    });
    //@ts-ignore
    tagLinks.setData(this.tagLinksFeatures);
  }

  /**
   * Speed factor for the animation. Keep it between 0 and 1.
   */
  private speedFactor: number = 0.3;
  private distance: number = 0;
  private terminatedLines: Set<string> = new Set();

  animateLines(source: Source, color?: string) {
    if (this.terminatedLines.size >= this.tagLinksFeatures.features.length) {
      this.distance = 0;
      this.terminatedLines = new Set();
    } else {
      let newFeatures: LineStringFeatureCollection = { features: [], type: "FeatureCollection" };
      for (let feat of this.tagLinksFeatures.features) {
        let point = along(feat.geometry, this.distance);
        if (point.geometry.coordinates == feat.geometry.coordinates[1]) {
          this.terminatedLines.add(feat.id);
        }
        let newFeature: LineStringFeature = {
          type: "Feature",
          id: `${point.geometry.coordinates[0]}-${point.geometry.coordinates[1]}`,
          properties:
          {
            color: color || '#ffffff',
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              feat.geometry.coordinates[0],
              point.geometry.coordinates as [number, number]
            ]
          }
        }
        newFeatures.features.push(newFeature);
      }
      this.distance = this.distance + this.speedFactor;
      //@ts-ignore
      source.setData(newFeatures);
      requestAnimationFrame(() => this.animateLines(source, color));
    }
  }

  public async setMapState(coordinates: WorldPoint, zoom?: number) {
    let state: MapState = { lng: coordinates[0], lat: coordinates[1], zoom: zoom || MapUtils.MAX_SAVED_ZOOM };
    await this.localStorage.setItem('map-state', JSON.stringify(state));
  }
} 