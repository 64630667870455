import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Input, input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { OnboardingService, StepData } from '../services/onboarding.service';
import { ActivatedRoute } from '@angular/router';
import { steps } from '../services/onboarding.service';
import { BehaviorSubject, map, Subject, Subscription } from 'rxjs';

export type StepState = {
  success: boolean;
  data?: string;
}

export interface Step {
  confirm: Subject<void>;
  processed: EventEmitter<StepState>;
  data?: StepData;
}

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent  implements OnInit, AfterViewInit, OnDestroy {
  
  constructor(public onboarding: OnboardingService, private route: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver) { }

  @ViewChild('step', { static: true, read: ViewContainerRef }) step!: ViewContainerRef;
  @ViewChild('stepContent', {read: ElementRef}) stepContent!: ElementRef<HTMLElement>;

  steps = steps;

  step$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  canGoBack$ = this.step$.pipe(map(step => step >= 1));
  canGoNext$ = this.step$.pipe(map(step => step < steps.length - 1));
  canSkip$ = this.route.data.pipe(map(data => data['skipable'] === true));

  confirm: Subject<void> = new Subject<void>();
  loading = false;

  routeSubscription?: Subscription;
  processedSubscription?: Subscription;
  componentRef?: ComponentRef<any>;

  ngOnInit() {
    this.routeSubscription = this.route.data.pipe(map(data => data['step'])).subscribe(step => {
      if (step == undefined) return;
      this.step$.next(step);
      this.loadComponent();
    });
  }

  ngAfterViewInit() {
    let style = document.createElement( 'style' );
    style.innerHTML = '.transition-effect .transition-cover { background: none; }';
    this.stepContent.nativeElement.shadowRoot?.appendChild(style);
  }

  ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
    this.processedSubscription?.unsubscribe();
  }

  loadComponent() {
    const step = steps[this.onboarding.currentStep];
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(step.data.component);
    const viewContainerRef = this.step;
    viewContainerRef.clear();
    this.componentRef?.destroy();
    this.processedSubscription?.unsubscribe();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (this.componentRef.instance as Step).data = step.data;
    (this.componentRef.instance as Step).confirm = this.confirm;
    this.processedSubscription = ((this.componentRef.instance as Step).processed as EventEmitter<StepState>).subscribe((data: StepState) => this.onStepProcessed(data));
  }


  skip() {
    this.onboarding.skipStep();
  }

  back() {
    this.onboarding.previousStep();
  }

  submit() {
    this.onboarding.nextStep();
  }

  complete() {
    this.onboarding.completeOnboarding();
  }
  
  processStep() {
    this.loading = true;
    this.confirm.next();
  }

  onStepProcessed(processed: StepState) {
    this.loading = false;
    if (processed.success) {
      this.onboarding.setStepState(this.step$.value, processed);
      this.onboarding.nextStep();
    }
  }
}
