import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChildren } from '@angular/core';
import { first, map, merge, Observable, skip, tap } from 'rxjs';
import { UserService } from '../../../data/user.service';
import { CommunityService } from 'app/src/app/data/community.service';
import { UserPersonalInfos, UserMinimalInfos, CommunityInfos, StandardError } from '@overlie/types';
import { AlertService } from 'app/src/app/utils/alert.service';
import { Router } from '@angular/router';
import { IonPopover, Platform } from '@ionic/angular';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  users?: Observable<UserMinimalInfos[]>;
  bannedUsers?: Observable<UserMinimalInfos[]>;
  @Input() community?: CommunityInfos;
  @Input({ required: true }) isModerator: boolean = false;
  @Input({ required: true }) isAdmin: boolean = false;
  @Input({ required: true }) isCreator: boolean = false;

  @Output() communityUpdated = new EventEmitter<CommunityInfos>();

  @ViewChildren('popover') popovers?: IonPopover[];

  displayPopover(e: Event, index: number) {
    if (!this.popovers) return;
    this.popovers.forEach((popover) => {
      popover.dismiss();
    });
    let popover = this.popovers.find((popover) => {
      //@ts-ignore
      return popover.el.id === `popover-${index}`
    });
    if (!popover) return;
    popover.event = e;
    popover.present();
  }

  constructor(private userService: UserService, 
    private communityService: CommunityService, 
    private alert: AlertService, 
    private router: Router,
    private platform: Platform) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    if (!this.community) return;
    this.users = this.communityService.getUsers(this.community.id).pipe(tap(users => {
      users.sort((a, b) => {
        if (this.isSelf(a.id)) return -1;
        if (this.isSelf(b.id)) return 1;
        if (this.isUserCreator(a.id) && !this.isUserCreator(b.id)) return -1;
        if (!this.isUserCreator(a.id) && this.isUserCreator(b.id)) return 1;
        if (this.isUserAdmin(a.id) && !this.isUserAdmin(b.id)) return -1;
        if (!this.isUserAdmin(a.id) && this.isUserAdmin(b.id)) return 1;
        if (this.isUserModerator(a.id) && !this.isUserModerator(b.id)) return -1;
        if (!this.isUserModerator(a.id) && this.isUserModerator(b.id)) return 1;
        return 0;
      });
    }));
    if (this.isAdmin || this.isModerator) this.bannedUsers = this.communityService.getBannedUsers(this.community.id);
  }

  seeUserProfile(userId: string) {
    this.router.navigate(['/explore/user', userId], {replaceUrl: this.platform.is('mobile')});
  }

  banUser(userId: string) {
    this.communityService.banUser(this.community!.id, userId).pipe(first()).subscribe((c) => {
      if (!c) return; 
      this.alert.success("Success", "User banned from community");
      this.communityUpdated.emit(c);
      this.getUsers();
    });
  }

  unbanUser(userId: string) {
    this.communityService.unbanUser(this.community!.id, userId).pipe(first()).subscribe((c) => {
      if (!c) return; 
      this.alert.success("Success", "User unbanned from community");
      this.communityUpdated.emit(c);
      this.getUsers();
    });
  }

  promoteAdmin(userId: string) {
    this.communityService.promoteAdmin(this.community!.id, userId).pipe(first()).subscribe((c) => {
      if (!c) return; 
      this.alert.success("Success", "User promoted to admin");
      this.communityUpdated.emit(c);
    });
  }

  demoteAdmin(userId: string) {
    this.communityService.demoteAdmin(this.community!.id, userId).pipe(first()).subscribe((c) => {
      this.alert.success("Success", "User demoted from admin");
      if (c) this.communityUpdated.emit(c);
    });
  }

  promoteModerator(userId: string) {
    this.communityService.promoteModerator(this.community!.id, userId).pipe(first()).subscribe((c) => {
      if (!c) return;  
      this.alert.success("Success", "User promoted to moderator");
      this.communityUpdated.emit(c);
    });
  }

  demoteModerator(userId: string) {
    this.communityService.demoteModerator(this.community!.id, userId).pipe(first()).subscribe((c) => {
      this.alert.success("Success", "User demoted from moderator");
      if (c) this.communityUpdated.emit(c);
    });
  }

  isUserModerator(userId: string): boolean {
    return this.community?.moderators.includes(userId) ?? false;
  }

  isUserAdmin(userId: string): boolean {
    return this.community?.admins.includes(userId) ?? false;
  }

  isUserCreator(userId: string): boolean {
    return this.community?.creatorId === userId;
  }

  isSelf(userId: string): boolean {
    return this.userService.userProfile.value.id === userId;
  }
}
