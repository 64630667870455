<ion-content #stepContent>
  <div class="step">
    <ng-template #step></ng-template>
  </div>
</ion-content>

@if ((canGoBack$ | async) === true) {
<ion-button (click)="back()" fill="false" class="step-button back">
  <ion-icon name="chevron-back"></ion-icon>
</ion-button>
}
@if (canSkip$ | async) {
<ion-button (click)="skip()" fill="false" class="step-button skip">
  Skip
</ion-button>
}
@if ((canGoBack$ | async) === false) {
<app-action-button type="primary" text="Start" (click)="processStep()" [loading]="loading"
  class="step-button action"></app-action-button>
}
@else if ((canGoNext$ | async) === true ) {
<app-action-button type="secondary" text="Next" (click)="processStep()" [loading]="loading"
  class="step-button action"></app-action-button>
} @else if ((canGoNext$ | async) === false ){
<app-action-button type="primary" text="Finish" (click)="complete()" [loading]="loading"
  class="step-button action"></app-action-button>
}