import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'app/src/environments/environment';
import { MapUtils } from '../MapUtils';

@Pipe({
  name: 'lifetime'
})
export class LifetimePipe implements PipeTransform {

  transform(value: number | undefined, args?: any): any {
    if (!value) {
      return 'Loading';
    }

    return Math.round(Math.max(0, MapUtils.calculateProtectionInHours(value)));
  }

}
