<button id="button" [ngClass]="[ type, disabled? 'disabled' : '', shadow? 'shadow': '']" [disabled]="disabled || loading">
    @if (loading) {
      <ion-spinner name="crescent"></ion-spinner>
    }
    @else {
      @if (icon) {
        <ion-icon id="icon" [class]="'icon-'+type" [name]="icon"></ion-icon>
      }
      @if (type !== "icon") {
        <div id="text" [class]="'text-'+type" [ngStyle]="{fontSize: fontSize + 'rem' || '1.2rem'}">
          {{text}}
        </div>
      }
    }
</button>
