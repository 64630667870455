import { Component, Input, OnInit } from '@angular/core';

export type PopoverButtons = {
  id: number;
  text: string;
  color?: "warning" | "danger";
  onClick: () => void;
}[];

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent  implements OnInit {

  constructor() { }
  
  @Input({required: true}) buttons?: PopoverButtons;
  
  ngOnInit() {
  }
}